<template>
  <b-card-code title="Set scene interface for current Editor">
    <div>
      <div @click="showLoaderExample = !showLoaderExample" id="show-loader-example">
        <p>{{ getShowLoaderText }}</p>
      </div>
      <loading-example v-if="showLoaderExample" :loadingSettings="loadingSettings" />
      <b-form class="position-relative" @submit.prevent="onSubmit">
        <b-card-code title="Set loading interface" border-variant="dark" bg-variant="light">
          <b-form-group label="Show loading circle" label-cols-lg="2" class="checkbox">
            <b-form-checkbox v-model="showLoadingCircle">
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="ml-1" label="Loading circle size" label-cols-lg="2" v-if="showLoadingCircle">
            <b-form-input
              id="bg-opacity"
              v-model="loadingCircleSize"
              number
              :state="circleSizeState"
              style="width: 80px;"
            />
          </b-form-group>
          <b-form-group class="ml-1" label="Loading circle width" label-cols-lg="2" v-if="showLoadingCircle">
            <b-form-input
              id="bg-opacity"
              v-model="loadingCircleWidth"
              number
              :state="circleWidthState"
              style="width: 80px;"
            />
          </b-form-group>
          <b-form-group label="Show loading logo" label-cols-lg="2" class="checkbox">
            <b-form-checkbox v-model="showLoadingLogo">
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="ml-1" v-if="showLoadingLogo" label="Loading logo width" label-cols-lg="2">
            <b-form-input
              id="bg-opacity"
              v-model="loadingLogoWidth"
              number
              :state="loadingLogoWidthState"
              style="width: 80px;"
            />
          </b-form-group>
          <b-form-group class="ml-1"  v-if="showLoadingLogo" label="Loading logo height" label-cols-lg="2">
            <b-form-input
              id="bg-opacity"
              v-model="loadingLogoHeight"
              number
              :state="loadingLogoHeightState"
              style="width: 80px;"
            />
          </b-form-group>
          <b-form-group
            v-if="showLoadingLogo"
            :label="'Custom loading logo (' + loadingLogoWidth + 'x' + loadingLogoHeight + ')'"
            label-cols-lg="2"
            class="checkbox ml-1"
          >
            <b-form-checkbox v-model="hasCustomLoadingLogo">
            </b-form-checkbox>
          </b-form-group>
          <b-form-file
            accept=".png, .jpg .gif"
            ref="file-input"
            v-if="hasCustomLoadingLogo"
            v-model="customLoadingLogoFile"
            class="col-4 ml-2"
            @change="whenLoadingLogoUpdated"
          />
          <b-form-group label="Show loading percentages" label-cols-lg="2" class="checkbox mt-3">
            <b-form-checkbox v-model="showLoadingPercentages">
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="showLoadingPercentages"
            label="Loading percentages size"
            label-for="form-mail"
            label-cols-lg="3"
            class="ml-1"
            style="width: 50%"
          >
            <b-form-input
              id="bg-opacity"
              v-model="loadingPercentagesSize"
              type="range"
              number
              min="20"
              max="150"
              step="1"
              style="cursor: pointer;"
            />
            <div style="display: flex; align-items: center; gap: 40px; flex-wrap: wrap;">
              <b-form-input
                id="bg-opacity"
                v-model="loadingPercentagesSize"
                number
                :state="loadingPercentagesSizeState"
                style="width: 80px;"
              />
            </div>
          </b-form-group>

          <div class="color-pickers">
            <div class="color-picker-item">
              <p>Loading page color</p>
              <chrome-picker :disabled="true" title="Color of loading page" v-model="loadingPageColor" />
            </div>
            <div class="color-picker-item" v-if="showLoadingPercentages">
              <p>Loading percentages colors</p>
              <chrome-picker :disabled="true" title="Color of loading percentages" v-model="loadingPercentagesColor" />
            </div>
            <div class="color-picker-item" v-if="showLoadingCircle">
              <p>Loading circle first color</p>
              <chrome-picker :disabled="true" title="Color of loading circle" v-model="loadingCirclesColor" />
            </div>
            <div class="color-picker-item" v-if="showLoadingCircle">
              <p>Loading circle second color</p>
              <chrome-picker :disabled="true" title="Second color of loading circle" v-model="loadingCirclesSecondColor" />
            </div>
          </div>
        </b-card-code>
        <b-form-group label="Show interface bar" label-cols-lg="2" class="checkbox">
          <b-form-checkbox v-model="showInterface">
          </b-form-checkbox>
        </b-form-group>

        <b-card-code v-if="showInterface" title="Set position of interface bar" border-variant="dark" bg-variant="light">
          <b-form-group label="Interface direction" label-cols-lg="2">
            <b-form-radio-group
              v-model="interfaceDirection"
              :options="interfaceDirectionOptions"
            />
          </b-form-group>
          <b-form-group label="Interface vertical position" label-cols-lg="2">
            <b-form-radio-group
              v-model="interfaceVerticalPosition"
              :options="interfaceVerticalPositionOptions"
            />
          </b-form-group>
          <b-form-group label="Interface horizontal position" label-cols-lg="2">
            <b-form-radio-group
              v-model="interfaceHorizontalPosition"
              :options="interfaceHorizontalPositionOptions"
            />
          </b-form-group>
        </b-card-code>
        <b-card-code 
          v-if="showInterface"
          title="Set buttons"
          border-variant="dark"
          bg-variant="light"
          class="iterface-buttons"
        >
          <b-form-group label="Show hide interface button" label-cols-lg="2" class="checkbox">
            <b-form-checkbox v-model="hideInterfaceButton">
            </b-form-checkbox>
          </b-form-group>
          <div v-if="hideInterfaceButton" style="padding-left: 10px; margin-top: -25px;">
            <b-form-group label='- Hide interface by default' label-cols-lg="2" class="checkbox">
              <b-form-checkbox v-model="hideInterface"></b-form-checkbox>
            </b-form-group>
          </div>
          <b-form-group label='Show zoom activating button' label-cols-lg="2" class="checkbox">
            <b-form-checkbox v-model="activateZoomButton">
            </b-form-checkbox>
          </b-form-group>
          <div v-if="activateZoomButton" style="padding-left: 10px; margin-top: -25px;">
            <b-form-group label='- Let users zoom scene by default' label-cols-lg="2" class="checkbox">
              <b-form-checkbox v-model="letUserZoom"></b-form-checkbox>
            </b-form-group>
          </div>
          <b-form-group label="Show fullscreen button" label-cols-lg="2" class="checkbox">
            <b-form-checkbox v-model="fullScreenButton">
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label="Show play-animation button" label-cols-lg="2" class="checkbox">
            <div style="display: flex;">
              <b-form-checkbox
                v-model="playAnimationButton"
                :disabled="!hasLicensePermission('setting-interface-buttons', 'show_play_animation_button')"
              ></b-form-checkbox>
              <a
                v-if="!hasLicensePermission('setting-interface-buttons', 'show_play_animation_button')"
                href="/pricing"
                target="_blank"
                title="Play animation for higher license"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="AlertTriangleIcon"
                  size="20"
                  style="color: #F00"
                />
              </a>
            </div>
          </b-form-group>
          <div v-if="playAnimationButton" style="padding-left: 10px; margin-top: -25px;">
            <b-form-group label='- Play animation by default' label-cols-lg="2" class="checkbox">
              <div style="display: flex;">
                <b-form-checkbox
                  v-model="playAnimation"
                  :disabled="!hasLicensePermission('setting-interface-buttons', 'play_animation_by_default')"
                ></b-form-checkbox>
                <a
                  v-if="!hasLicensePermission('setting-interface-buttons', 'play_animation_by_default')"
                  href="/pricing"
                  target="_blank"
                  title="Play animation by default for higher license"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="AlertTriangleIcon"
                    size="20"
                    style="color: #F00"
                  />
                </a>
              </div>
            </b-form-group>
          </div>
          <b-form-group label="Hide 3Digital logo" label-cols-lg="2" class="checkbox">
            <div style="display: flex;">
              <b-form-checkbox
                v-model="hideLogo"
                :disabled="!hasLicensePermission('setting-interface-buttons', 'hide_3digital_logo')"
              >
                <a
                  v-if="!hasLicensePermission('setting-interface-buttons', 'hide_3digital_logo')"
                  href="/pricing"
                  target="_blank"
                  title="Hide logo for higher license"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="AlertTriangleIcon"
                    size="20"
                    style="color: #F00"
                  />
                </a>
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-card-code>

        <b-card-code v-if="showInterface" title="Set design of buttons" border-variant="dark" bg-variant="light">
          <div class="color-pickers">
            <div class="color-picker-item">
              <div v-if="!hasLicensePermission('setting-buttons-design', 'buttons_color')" class="block-div"></div>
              <p>Color of buttons</p>
              <chrome-picker :disabled="true" title="Buttons color in normal mode" v-model="buttonsColor" />
            </div>
            <a
              v-if="!hasLicensePermission('setting-buttons-design', 'buttons_color')"
              href="/pricing"
              target="_blank"
              title="Buttons color changing for higher license"
            >
              <feather-icon
                class="cursor-pointer mr-4"
                icon="AlertTriangleIcon"
                size="20"
                style="color: #F00; margin-left: -20px;"
              />
            </a>
            <div class="color-picker-item">
              <div v-if="!hasLicensePermission('setting-buttons-design', 'buttons_hover_color')" class="block-div"></div>
              <p>Color of buttons on hover</p>
              <chrome-picker title="Users will see this color when hovering over the buttons" v-model="buttonsColorHover" />
            </div>
            <a
              v-if="!hasLicensePermission('setting-buttons-design', 'buttons_hover_color')"
              href="/pricing"
              target="_blank"
              title="Buttons hover color chaning for higher license"
            >
              <feather-icon
                class="cursor-pointer mr-4"
                icon="AlertTriangleIcon"
                size="20"
                style="color: #F00; margin-left: -20px;"
              />
            </a>
          </div>
          <b-form-group
              label="Interface bar height"
              label-for="form-mail"
              label-cols-lg="3"
              style="width: 50%; margin-top: 40px;"
            >
              <b-form-input
                id="bg-opacity"
                v-model="interfaceBarHeight"
                type="range"
                number
                min="20"
                max="150"
                step="1"
                style="cursor: pointer;"
                :disabled="!hasLicensePermission('setting-buttons-design', 'interface_size')"
              />
              <div style="display: flex; align-items: center; gap: 40px; flex-wrap: wrap;">
                <b-form-input
                  id="bg-opacity"
                  v-model="interfaceBarHeight"
                  number
                  :state="interfaceBarHeightState"
                  style="width: 80px;"
                  :disabled="!hasLicensePermission('setting-buttons-design', 'interface_size')"
                />
                <a
                  v-if="!hasLicensePermission('setting-buttons-design', 'interface_size')"
                  href="/pricing"
                  target="_blank"
                  title="Buttons size chaning for higher license"
                >
                  <feather-icon
                    class="cursor-pointer mr-4"
                    icon="AlertTriangleIcon"
                    size="20"
                    style="color: #F00; margin-left: -20px;"
                  />
                </a>
              </div>
              
            </b-form-group>
        </b-card-code>

        <div class="d-flex justify-content-center">
          <b-button
            ref="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="busyButton || !interfaceBarHeightState"
          >
            Submit
          </b-button>
        </div>

        <div v-if="busy" no-wrap @shown="onShown" @hidden="onHidden">
          <div class="busy-background">
            <div
              v-if="processing"
              class="text-center p-3 bg-primary text-light rounded processing"
            >
              <feather-icon icon="UploadCloudIcon" size="20" />
              <div class="mb-2">Preservation...</div>
              <b-progress
                min="1"
                max="5"
                :value="counter"
                variant="success"
                height="6px"
                class="mx-n3"
              />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3 confirmate-buttons"
            >
              <b-card-text class="font-weight-bolder confirmation-text">
                Are you sure?
              </b-card-text>
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgb(234, 84, 85)'"
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgb(40, 199, 111)'"
                  variant="outline-success"
                  @click="onOK"
                >
                  OK
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </b-card-code>
</template>

<script>
import { Chrome } from "vue-color";
import BCardCode from "@core/components/b-card-code";
import { errorMessage } from "@/auth/utils";
import {
  BButton,
  BForm,
  BProgress,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getBase64 } from "/src/utils/utils"
import LoadingExample from "/src/views/components/interface/LoadingExample.vue"


export default {
  components: {
    LoadingExample,
    BButton,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BCardCode,
    BFormInput,
    BCardText,
    BFormCheckbox,
    "chrome-picker": Chrome,
  },
  directives: {
    Ripple,
  },
  computed: {
    getShowLoaderText() {
      if (this.showLoaderExample) {
        return "Hide loader example"
      }

      return "Show loader example"
    },
    loadingSettings() {
      return {
        showLoadingPercentages: this.showLoadingPercentages,
        showLoadingLogo: this.showLoadingLogo,
        showLoadingCircle: this.showLoadingCircle,
        loadingCircleSize: this.loadingCircleSize,
        loadingCircleWidth: this.loadingCircleWidth,
        loadingLogoWidth: this.loadingLogoWidth,
        loadingLogoHeight: this.loadingLogoHeight,
        loadingPercentagesColor: this.loadingPercentagesColor,
        loadingPercentagesSize: this.loadingPercentagesSize,
        loadingCirclesColor: this.loadingCirclesColor,
        loadingCirclesSecondColor: this.loadingCirclesSecondColor,
        loadingPageColor: this.loadingPageColor,
        customLoadingLogoData: this.customLoadingLogoData,
        hasCustomLoadingLogo: this.hasCustomLoadingLogo,
      }
    },
    circleSizeState() {
      return this.loadingCircleSize > 0
    },
    circleWidthState() {
      return this.loadingCircleSize > 0
    },
    loadingLogoWidthState() {
      return this.loadingLogoWidth > 0
    },
    loadingLogoHeightState() {
      return this.loadingLogoHeight > 0
    },
    interfaceBarHeightState() {
      return this.interfaceBarHeight >= 20 && this.interfaceBarHeight <= 150
    },
    loadingPercentagesSizeState() {
      return this.loadingPercentagesSize >= 20 && this.loadingPercentagesSize <= 150
    },
    settings() {
      return this.$store.state.Editor.cameraSetting;
    },
    editor() {
      return this.$store.state.Editor.editor;
    },
    licensePermissions() {
      return this.$store.getters["app/LICENSE_PERMISSIONS"]
    },
  },
  data() {
    return {
      busy: false,
      showLoaderExample: false,
      busyButton: true,
      processing: false,
      counter: 1,
      interval: null,
      showInterface: false,
      fullScreenButton: false,
      hideInterfaceButton: false,
      activateZoomButton: false,
      hideLogo: false,
      hideInterface: false,
      playAnimationButton: false,
      hasCustomLoadingLogo: false,
      customLoadingLogoFile: null,
      customLoadingLogoData: '',
      playAnimation: false,
      interfaceBarHeight: 30,
      loadingPercentagesSize: 40,
      interfaceDirection: 'vertical',
      interfaceDirectionOptions: [
        { text: 'Vertical', value: 'vertical' },
        { text: 'Horizontal', value: 'horizontal' },
      ],
      interfaceHorizontalPosition: 'left',
      interfaceHorizontalPositionOptions: [
        { text: 'Left', value: 'left' },
        { text: 'Right', value: 'right' },
      ],
      interfaceVerticalPosition: 'top',
      interfaceVerticalPositionOptions: [
        { text: 'Top', value: 'top' },
        { text: 'Bottom', value: 'bottom' },
      ],
      buttonsColor: {"hex8": "#000000FF"}, "rgba": {"r": 0, "g": 0, "b": 0, "a": 255},
      buttonsColorHover: {"hex8": "#FFFFFFFF", "rgba": {"r": 255, "g": 255, "b": 255, "a": 255}},
      loadingPercentagesColor: {"hex8": "#000000FF", "rgba": {"r": 0, "g": 0, "b": 0, "a": 255}},
      loadingCirclesColor: {"hex8": "#F3F3F3FF", "rgba": {"r": 243, "g": 243, "b": 243, "a": 255}},
      loadingCirclesSecondColor: {"hex8": "#c4c9cc", "rgba": {"r": 190, "g": 197, "b": 201, "a": 255}},
      loadingPageColor: {"hex": "#FFFFFF"},
      letUserZoom: false,
      showLoadingPercentages: true,
      showLoadingLogo: true,
      showLoadingCircle: true,
      loadingCircleSize: 170,
      loadingCircleWidth: 6,
      loadingLogoWidth: 90,
      loadingLogoHeight: 90,
    };
  },
  beforeDestroy() {
    this.clearInterval();
  },
  setup() {
    const projectData = ref(null);

    store
      .dispatch("Project/loadProject", store.state.Project.project.id)
      .then((response) => {
        projectData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          projectData.value = undefined;
        }
      });

    return {
      projectData,
    };
  },
  methods: {
    async whenLoadingLogoUpdated(event) {
      this.customLoadingLogoData = await getBase64(event.target.files[0])
    },
    hasLicensePermission(permission_type, permission_field) {
      // Look in license_permission.json to manage permission_type and permission_field
      try {
        return this.isLicenseChosen(this.licensePermissions[permission_type][permission_field].split(" "))
      } catch {
        return true
      }
    },
    isLicenseChosen(licenseList) {
      for (let license of licenseList) {
        if (license == this.projectData.project_license_type) {
          return true
        }
      }
      return false
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus();
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus();
    },
    onSubmit() {
      this.processing = false;
      this.busy = true;
    },
    onCancel() {
      this.busy = false;
    },
    updateProcessing() {
      // Counter is going up from 1 to 5
      // Simulate an async request
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 5) {
          this.counter += 1;
        } else {
          this.clearInterval();
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.busy = this.processing = false;
          });
        }
      }, 350);
    },
    async onOK() {
      this.processing = true;
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', true)
      this.counter = 1;
      if (this.customLoadingLogoFile) {
        this.customLoadingLogoData = await getBase64(this.customLoadingLogoFile)
      }
      const bgSettings = this.settings;

      bgSettings.interface = {
        showInterface: this.showInterface,
        fullScreenButton: this.fullScreenButton,
        activateZoomButton: this.activateZoomButton,
        direction: this.interfaceDirection,
        horizontalPosition: this.interfaceHorizontalPosition,
        verticalPosition: this.interfaceVerticalPosition,
        buttonsColor: this.buttonsColor,
        buttonsColorHover: this.buttonsColorHover,
        loadingPageColor: this.loadingPageColor,
        loadingPercentagesColor: this.loadingPercentagesColor,
        letUserZoom: this.letUserZoom,
        hideInterfaceButton: this.hideInterfaceButton,
        playAnimationButton: this.playAnimationButton,
        playAnimation: this.playAnimation,
        interfaceBarHeight: this.interfaceBarHeight,
        hideInterface: this.hideInterface,
        hideLogo: this.hideLogo,
        hasCustomLoadingLogo: this.hasCustomLoadingLogo,
        customLoadingLogoData: this.customLoadingLogoData,
        loadingPercentagesSize: this.loadingPercentagesSize,
        loadingCirclesColor: this.loadingCirclesColor,
        loadingCirclesSecondColor: this.loadingCirclesSecondColor,
        showLoadingPercentages: this.showLoadingPercentages,
        showLoadingLogo: this.showLoadingLogo,
        showLoadingCircle: this.showLoadingCircle,
        loadingCircleSize: this.loadingCircleSize,
        loadingCircleWidth: this.loadingCircleWidth,
        loadingLogoWidth: this.loadingLogoWidth,
        loadingLogoHeight: this.loadingLogoHeight,
      }
      
      await this.setBackgroundSettings(bgSettings);
      this.updateProcessing();
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', false)
      await this.loadEditorAsync();
    },

    async setBackgroundSettings(bgSettings) {
      // PATCH Editor with new settings_json file
      await this.$store.commit("Editor/SET_CAMERA", bgSettings);
      await this.$store
        .dispatch("Editor/changeSetting", this.$store.state.Editor.editor.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:
                "You have successfully update Background/Environment general setting",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },

    async loadEditorAsync() {
      const _this = this
      // Set headers for fetch
      let myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-cache');

      // Set request options for fetch
      const myInit = {
        method: 'GET',
        headers: myHeaders,
      };

      await this.$store
        .dispatch("Editor/getEditor", this.$store.state.Editor.editor.id)
        .then((res) => {
          this.settings_json_s3_path = res.data.settings_json_s3_path;
        
        this.$store.commit("Editor/SET_EDITOR", res.data);
        this.$store
          .dispatch("Editor/setCameraSetting") // Fetch settings json file
          .then((response) => response.json())
          .then((data) => {
            this.$store.commit("Editor/SET_CAMERA", data);
          });
      })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });

      // Create settings_json_data with initial settings
      let settings_json_data = {interface: {
        showInterface: this.showInterface,
        fullScreenButton: this.fullScreenButton,
        activateZoomButton: this.activateZoomButton,
        direction: this.interfaceDirection,
        horizontalPosition: this.interfaceHorizontalPosition,
        verticalPosition: this.interfaceVerticalPosition,
        buttonsColor: this.buttonsColor,
        letUserZoom: this.letUserZoom,
        hideInterfaceButton: this.hideInterfaceButton,
        playAnimationButton: this.playAnimationButton,
        playAnimation: this.playAnimation,
        buttonsColorHover: this.buttonsColorHover,
        interfaceBarHeight: this.interfaceBarHeight,
        hideInterface: this.hideInterface,
        hideLogo: this.hideLogo,
        hasCustomLoadingLogo: this.hasCustomLoadingLogo,
        customLoadingLogoData: this.customLoadingLogoData,
        loadingPageColor: this.loadingPageColor,
        loadingPercentagesColor: this.loadingPercentagesColor,
        loadingPercentagesSize: this.loadingPercentagesSize,
        loadingCirclesColor: this.loadingCirclesColor,
        loadingCirclesSecondColor: this.loadingCirclesSecondColor,
        showLoadingPercentages: this.showLoadingPercentages,
        showLoadingLogo: this.showLoadingLogo,
        showLoadingCircle: this.showLoadingCircle,
        loadingCircleSize: this.loadingCircleSize,
        loadingCircleWidth: this.loadingCircleWidth,
        loadingLogoWidth: this.loadingLogoWidth,
        loadingLogoHeight: this.loadingLogoHeight,
      }}

      // Get data by settings_json_s3_path PATH and myInit request options
      await fetch(this.settings_json_s3_path, myInit)
        .then(function (response) {
          return response.json() // Get json data first
        }).then(function(response) {
          if (response.interface) {
            for (let property in response.interface) {
              settings_json_data.interface[property] = response.interface[property]
            }
          }
          _this.editorIsLoaded = true
          _this.busyButton = false
        })

      // Initialize settings data here
      this.showInterface = settings_json_data.interface.showInterface
      this.fullScreenButton = settings_json_data.interface.fullScreenButton
      this.activateZoomButton = settings_json_data.interface.activateZoomButton
      this.interfaceDirection = settings_json_data.interface.direction
      this.interfaceHorizontalPosition = settings_json_data.interface.horizontalPosition
      this.interfaceVerticalPosition = settings_json_data.interface.verticalPosition
      this.letUserZoom = settings_json_data.interface.letUserZoom
      this.hideInterfaceButton = settings_json_data.interface.hideInterfaceButton
      this.playAnimationButton = settings_json_data.interface.playAnimationButton
      this.playAnimation = settings_json_data.interface.playAnimation
      this.buttonsColorHover = settings_json_data.interface.buttonsColorHover
      this.interfaceBarHeight = settings_json_data.interface.interfaceBarHeight
      this.hideInterface = settings_json_data.interface.hideInterface
      this.hideLogo = settings_json_data.interface.hideLogo
      this.buttonsColor = settings_json_data.interface.buttonsColor
      this.hasCustomLoadingLogo = settings_json_data.interface.hasCustomLoadingLogo
      this.customLoadingLogoData = settings_json_data.interface.customLoadingLogoData
      this.loadingPageColor = settings_json_data.interface.loadingPageColor
      this.loadingPercentagesColor = settings_json_data.interface.loadingPercentagesColor
      this.loadingPercentagesSize = settings_json_data.interface.loadingPercentagesSize
      this.loadingCirclesColor = settings_json_data.interface.loadingCirclesColor
      this.loadingCirclesSecondColor = settings_json_data.interface.loadingCirclesSecondColor
      this.showLoadingPercentages = settings_json_data.interface.showLoadingPercentages
      this.showLoadingLogo = settings_json_data.interface.showLoadingLogo
      this.showLoadingCircle = settings_json_data.interface.showLoadingCircle
      this.loadingCircleSize = settings_json_data.interface.loadingCircleSize
      this.loadingCircleWidth = settings_json_data.interface.loadingCircleWidth
      this.loadingLogoWidth = settings_json_data.interface.loadingLogoWidth
      this.loadingLogoHeight = settings_json_data.interface.loadingLogoHeight
    },
  },
  mounted() {
    this.loadEditorAsync();
  },
}
</script>

<style lang="scss" scoped>
.busy-background {
  background-color: #80808031;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
}
.confirmate-buttons {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 100px);
}
.processing {
  position: absolute;
  top: calc(50% - 100px);
  left: 50%;
}
.confirmation-text {
  color: rgb(48, 44, 44);
}
.pickerColor {
  width: 20%;
  padding-bottom: 20%;
  margin-bottom: 10px;
  border: 1px solid black;
}

.color-pickers {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.color-picker-item {
  position: relative;
  margin-right: 30px;
}

.block-div {
  position: absolute;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  z-index: 10;
}

#show-loader-example {
  position: absolute;
  padding: 10px;
  border-radius: 50px;
  left: calc(50% - 45px);
  top: 100px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: 30px;
  background-color: #DFDFDF;
  cursor: pointer;

  &:hover {
    background-color: #E9E7E7;
  }
  p {
    margin: 0;
  }
}

::v-deep .iterface-buttons .card-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
