<template>
    <div id="loader-example">
        <div id="loader">
            <div id="loader-circle" :style="loaderCircleStyles"></div>
            <svg id="loader-logo" :style="loaderLogoStyles"></svg>
            <div id="loading-progress" :style="loaderProgressStyles"><p>{{ getFakeLoadingPercentages }}%</p></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'loading-example',
    data() {
        return {
            fakeLoadingPercentages: 0,
        };
    },
    computed: {
        getFakeLoadingPercentages() {
            setTimeout(() => {
                this.fakeLoadingPercentages += 1
                this.fakeLoadingPercentages = this.fakeLoadingPercentages == 100 ? 0 : this.fakeLoadingPercentages
            }, 500)
            return this.fakeLoadingPercentages
        },
        loaderCircleStyles() {
            const circleStyles = {}
            if (this.loadingSettings.showLoadingCircle === false) {
                circleStyles.display = 'none'
            }
            if (this.loadingSettings.loadingCircleSize) {
                circleStyles.width = `${this.loadingSettings.loadingCircleSize}px`
                circleStyles.height = `${this.loadingSettings.loadingCircleSize}px`
            }
            if (this.loadingSettings.loadingCircleWidth) {
                circleStyles.borderWidth = `${this.loadingSettings.loadingCircleWidth}px`
            }
            if (this.loadingSettings.loadingCirclesColor && this.loadingSettings.loadingCirclesColor.hex8) {
                circleStyles.borderColor = this.loadingSettings.loadingCirclesColor.hex8
            }
            if (this.loadingSettings.loadingCirclesSecondColor && this.loadingSettings.loadingCirclesSecondColor.hex8) {
                circleStyles.borderTopColor = this.loadingSettings.loadingCirclesSecondColor.hex8
            }

            return circleStyles
        },
        loaderLogoStyles() {
            const logoStyles = {}
            if (this.loadingSettings.hasCustomLoadingLogo) {
                logoStyles.background = `url(${this.loadingSettings.customLoadingLogoData}) no-repeat`
                logoStyles.backgroundSize = `90px`
            }
            if (this.loadingSettings.showLoadingLogo === false) {
                logoStyles.display = 'none'
            }
            if (this.loadingSettings.loadingLogoWidth) {
                logoStyles.width = `${this.loadingSettings.loadingLogoWidth}px`
            }
            if (this.loadingSettings.loadingLogoHeight) {
                logoStyles.height = `${this.loadingSettings.loadingLogoHeight}px`
            }

            return logoStyles
        },
        loaderProgressStyles() {
            const progressStyles = {}
            if (this.loadingSettings.showLoadingPercentages === false) {
                console.log("NONE")
                progressStyles.display = 'none'
            }
            if (this.loadingSettings.loadingPercentagesSize) {
                progressStyles.fontSize = `${this.loadingSettings.loadingPercentagesSize}px`
            }
            if (this.loadingSettings.loadingPercentagesColor && this.loadingSettings.loadingPercentagesColor.hex8) {
                progressStyles.color = this.loadingSettings.loadingPercentagesColor.hex8
            }

            return progressStyles
        },
    },
    props: {
        loadingSettings: {
            type: Object
        },
    }
}
</script>
<style lang="scss">
#loader {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 90px;
    align-items: center;
    text-align: center;
    z-index: 10;
    left: calc(50%);
    top: 10%;

    #loader-circle {
        border: 6px solid #f3f3f3;
        border-radius: 50%;
        border-top: 6px solid #c4c9cc;
        width: 170px;
        height: 170px;
        opacity: 0.5;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
        position: absolute;
        top: -29px;
    }

    #loader-logo {
        background: url(/logo-2.png) no-repeat;
        background-size: 90px;
        width: 90px;
        height: 90px;
    }

    #loading-progress {
        font-size: 40px;
        color: black;
        p {
            line-height: 0px;
            font-family: "Roboto", sans-serif;
        }
    }
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>